<template>
    <div class="sa-form-section">
        <div>
            <h3>{{ this.$i18n.t("patient.afa.lblVisit") }}</h3>
        </div>
        <div class="sa-tab">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-tabs content-class="mt-3" fill>
                        <b-tab :title="this.$i18n.t('patient.afa.lblSportsMedicalEvaluationForm')">
                            <div>
                                <afa-scheda-valutazione-medico-sportiva-view-component :jsonData="jsonData"></afa-scheda-valutazione-medico-sportiva-view-component>
                            </div>
                        </b-tab>
                        <b-tab :title="this.$i18n.t('patient.afa.lblAnamnesis')">
                            <afa-anamnesi-view-component :jsonData="jsonData"></afa-anamnesi-view-component>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <span :class="getColorEsameObiettivo()"><b-icon :icon="getIconEsameObiettivo()"></b-icon>&nbsp;Esame Obiettivo</span>
                            </template>
                            <afa-esame-obiettivo-view-component :jsonData="jsonData"></afa-esame-obiettivo-view-component>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <span :class="getColorElettrocardiogramma()"><b-icon :icon="getIconElettrocardiogramma()"></b-icon>&nbsp;Elettrocardiogramma</span>
                            </template>
                            <afa-elettrocardiogramma-view-component :jsonData="jsonData"></afa-elettrocardiogramma-view-component>
                        </b-tab>
                        <b-tab :title="this.$i18n.t('patient.afa.lblAnthropometrics')">
                            <afa-antropometrici-view-component :jsonData="jsonData"></afa-antropometrici-view-component>
                        </b-tab>
                        <b-tab :title="this.$i18n.t('patient.afa.lblFitness')" :edit="edit">
                            <afa-fitness-view-component :jsonData="jsonData"></afa-fitness-view-component>
                        </b-tab>
                        <b-tab :title="this.$i18n.t('patient.afa.lblOtherExams')">
                            <afa-altri-esami :data="jsonData" :edit="edit"></afa-altri-esami>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <span :class="getColorGiudizioFinale()"><b-icon :icon="getIconGiudizioFinale()"></b-icon>&nbsp;Giudizio Finale</span>
                            </template>
                            <afa-giudizio-finale-view-component :jsonData="jsonData"></afa-giudizio-finale-view-component>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
// import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import AfaAltriEsami from "./AfaAltriEsami.vue";
import AfaSchedaValutazioneMedicoSportivaViewComponent from "./AfaSchedaValutazioneMedicoSportivaViewComponent.vue";
import AfaAnamnesiViewComponent from "./AfaAnamnesiViewComponent.vue";
import AfaEsameObiettivoViewComponent from "./AfaEsameObiettivoViewComponent.vue";
import AfaElettrocardiogrammaViewComponent from "./AfaElettrocardiogrammaViewComponent.vue";
import AfaAntropometriciViewComponent from "./AfaAntropometriciViewComponent.vue";
import AfaFitnessViewComponent from "./AfaFitnessViewComponent.vue";
import AfaGiudizioFinaleViewComponent from "./AfaGiudizioFinaleViewComponent.vue";

export default {
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {
        AfaAltriEsami,
        AfaSchedaValutazioneMedicoSportivaViewComponent,
        AfaAnamnesiViewComponent,
        AfaEsameObiettivoViewComponent,
        AfaElettrocardiogrammaViewComponent,
        AfaAntropometriciViewComponent,
        AfaFitnessViewComponent,
        AfaGiudizioFinaleViewComponent,
    },
    data() {
        return {
            jsonData: { idPaziente: null },
            pathRest: "/medicinadellosportafa",
            linkEdit: null,
            linkback: null,
            edit: false,
            showModalLoading: false,
        };
    },
    computed: {},
    mounted() {
        let me = this;
        console.log(me.idEvento);
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            axios
                .get(link + me.idEvento)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.idPaziente = me.jsonData.idPaziente;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getColorEsameObiettivo() {
            let me = this;
            let colore = me.jsonData.firmaEo != null ? "text-success" : "text-danger";
            return colore;
        },
        getColorElettrocardiogramma() {
            let me = this;
            let colore = me.jsonData.firmaEcg != null ? "text-success" : "text-danger";
            return colore;
        },
        getColorGiudizioFinale() {
            let me = this;
            let colore = me.jsonData.firmaGf != null ? "text-success" : "text-danger";
            return colore;
        },

        getIconEsameObiettivo() {
            let me = this;
            let icon = me.jsonData.firmaEo != null ? "check2" : "exclamation-triangle";
            return icon;
        },
        getIconElettrocardiogramma() {
            let me = this;
            let icon = me.jsonData.firmaEcg != null ? "check2" : "exclamation-triangle";
            return icon;
        },
        getIconGiudizioFinale() {
            let me = this;
            let icon = me.jsonData.firmaGf != null ? "check2" : "exclamation-triangle";
            return icon;
        },
    },
};
</script>
